<template>
    <div>
        <v-img :src="src" 
            style="width:150px;" 
            @touchstart="active=true" 
            @touchend.stop.prevent="active=false;$emit('seleccion')"  
            @click.stop.prevent="$emit('seleccion')"  
            class="mx-auto">             
        </v-img>      
    </div>
</template>
<script>
export default {
    data () { return {
        active: false,
    }},
    computed: {
        src: function () { 
            return !this.active ? '/img/deseleccionar1.svg' : '/img/deseleccionar2.svg'
        }
    },
}
</script>